import { useEffect, useState } from "react";
import axios from "axios";

export default function useFirearm(defaultItems) {
  const [items, setItems] = useState(defaultItems);
  const [itemsOffset, setItemsOffset] = useState(16);
  const [liveAuctionTotalCount, setLiveAuctionTotalCount] = useState(0);
  const [recentlySoldTotalCount, setRecentlySoldTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isHydrated, setIsHydrated] = useState(false);
  const [isSearchPage, setIsSearchPage] = useState(false);
  const [error, setError] = useState(null);
  const [viewAllBtnIsVisible, setViewAllBtnIsVisible] = useState(true);
  const [isInfiniteScroll, setIsInfiniteScroll] = useState(false);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedCaliber, setSelectedCaliber] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [urlParams, setUrlParams] = useState(null);
  const [query, setQuery] = useState("");
  const [displayedSearchQuery, setDisplayedSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("ending");
  const [manufacturersOptions, setManufacturersOptions] = useState([]);
  const [caliberOptions, setCaliberOptions] = useState([]);
  const [platformOptions, setPlatformOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [itemsSoldRecently, setItemsSoldRecently] = useState([]);
  const [liveAuctionHasFetchedAll, setLiveAuctionHasFetchedAll] = useState(false);
  const [itemsHasReachedEnd, setItemsHasReachedEnd] = useState(false);
  

  const getFirearms = async (params) => {
    setIsLoading(true);
    const response = await axios.get("/api/firearms", {
      params,
    });
    setIsLoading(false);

    return response.data;
  };

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  useEffect(() => {
    if (!isHydrated) return;
    const request = axios.CancelToken.source();

    // if (text.length === 0) return setData([]);

    setItemsOffset(16);

    const params = {
      sort_by: sortBy,
      order_by: sortBy === "ending" ? "asc" : "desc",
      is_ending: ['ending', 'new'].includes(sortBy) ? true : null,
      is_sold: sortBy === "recently_sold" ? true : null,
      offset: 0,
      manufacturer: selectedManufacturer?.code ?? null,
      caliber: selectedCaliber?.code ?? null,
      platform: selectedPlatform?.code ?? null,
      category: selectedCategory?.code ?? null,
      q: query,
    };

    const options = {
      method: "GET",
      url: "/api/firearms",
      params,
      cancelToken: request.token,
    };

    setIsLoading(true);
    axios
      .request(options)
      .then((res) => {
        setItems(res.data.data);
        setLiveAuctionTotalCount(res.data.total_count);
        setManufacturersOptions(
          res.data.filters.manufacturers.map((manufacturer) => {
            return {
              name: manufacturer,
              code: manufacturer,
            };
          })
        );
        setCaliberOptions(
          res.data.filters.calibers.map((caliber) => {
            return {
              name: caliber,
              code: caliber,
            };
          })
        );
        setPlatformOptions(
          res.data.filters.platforms.map((platform) => {
            return {
              name: platform,
              code: platform,
            };
          })
        );
        setCategoryOptions(
          res.data.filters.categories.map((category) => {
            return {
              name: category,
              code: category,
            };
          })
        );

        setViewAllBtnIsVisible(true);
        setIsInfiniteScroll(false);
        setIsLoading(false);
        setDisplayedSearchQuery(query);

        if (isSearchPage && res.data.data.length < 16) {
          setLiveAuctionHasFetchedAll(true);
          axios.get("/api/firearms", {
            params: {
              ...params,
              sort_by: "recently_sold",
              order_by: "desc",
              is_ending: null,
              is_sold: true,
              offset: 0,
            }
          }).then((itemsSoldResponse) => {
            setItemsSoldRecently(itemsSoldResponse.data.data);
            setRecentlySoldTotalCount(itemsSoldResponse.data.total_count);
          })
        } else {
          setLiveAuctionHasFetchedAll(false)
          setItemsSoldRecently([]);
          setRecentlySoldTotalCount(0);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        setError(err);
      })
      .finally(() => {
        setItemsHasReachedEnd(false);
      });

    return () => request.cancel();
  }, [
    query,
    sortBy,
    selectedManufacturer,
    selectedCaliber,
    selectedPlatform,
    selectedCategory,
  ]);

  return {
    items,
    setItems,
    itemsOffset, 
    setItemsOffset,
    getFirearms,
    isLoading,
    query,
    setQuery,
    sortBy,
    setSortBy,
    selectedManufacturer,
    setSelectedManufacturer,
    selectedCaliber,
    setSelectedCaliber,
    selectedPlatform,
    setSelectedPlatform,
    selectedCategory,
    setSelectedCategory,
    viewAllBtnIsVisible,
    setViewAllBtnIsVisible,
    isInfiniteScroll,
    setIsInfiniteScroll,
    manufacturersOptions,
    setManufacturersOptions,
    caliberOptions,
    setCaliberOptions,
    platformOptions,
    setPlatformOptions,
    categoryOptions,
    setCategoryOptions,
    liveAuctionTotalCount,
    setLiveAuctionTotalCount,
    isSearchPage,
    setIsSearchPage,
    itemsSoldRecently,
    setItemsSoldRecently,
    displayedSearchQuery,
    setDisplayedSearchQuery,
    recentlySoldTotalCount,
    setRecentlySoldTotalCount,
    liveAuctionHasFetchedAll,
    setLiveAuctionHasFetchedAll,
    itemsHasReachedEnd,
    setItemsHasReachedEnd
  };
}
